<template>
  <div>
    <Header @loginUp="loginUp"></Header>
    <el-container style="padding: 15px 15px 10px 15px" class="container">
      <el-aside class="aside"  v-if="tabList.length != 0 || getPageList.length != 0">
        <LeftTab :tabList="tabList" :getPageList="getPageList"></LeftTab>
      </el-aside>
      <el-main class="main">
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive" />
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive" />
      </el-main>
    </el-container>
  </div>
</template>

<script>
import Header from "./Header.vue";
import LeftTab from "./LeftTab.vue";
export default {
  props: ["tabList", "getPageList"],
  components: {
    Header,
    LeftTab,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    loginUp() {
      window.sessionStorage.clear();
      sessionStorage.clear();
      window.location.reload();
      this.$message({
        type: "success",
        message: "退出成功",
      });
    },
  },
};
</script>

<style lang="less" scoped>
// 左边
.aside {
  width: 200px !important;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100vh;
  margin-top: -60px;
  background-color: white;
}
</style>