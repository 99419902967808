<template>
  <el-header class="header">
    <div class="logo" @click="linkTo">
      <img src="../../assets/images/logo.png" />云体系平台
    </div>
    <div class="nav">
      <div v-for="(item, index) in list" :key="index" class="menu">
        <span
          class="item"
          :class="{ navActive: index == tabIndex }"
          @click="clickTab(index, item.path)"
        >{{ item.title }}</span>
        <i :class="show ? 'el-icon-arrow-down' : 'el-icon-arrow-up'" v-if="item.children"></i>
        <div class="menuItem" v-show="show">
          <template v-for="(v, i) in item.children">
            <div
              :class="{ itemActive: i == tabItemIndex }"
              :key="v.title"
              v-if="v.tenantId == tenantId || v.tenantId == undefined"
              @click="clickTabItem(i, v.path)"
            >{{ v.title }}</div>
          </template>
        </div>
      </div>
    </div>
    <span class="login-out" @click="loginUp">退出登录</span>
  </el-header>
</template>

<script>
export default {
  data() {
    return {
      tabIndex: 0,
      tabItemIndex: 0,
      show: false,
      list: [
        { title: "首页", path: "./home" },
        { title: "系统设置", path: "./system-View" },
        { title: "基础设置", path: "./basic-view" },
        { title: "题库管理", path: "./question-bank-view" },
        { title: "统计分析", path: "./statistic-view" },
        { title: "供应商审核", path: "./VDA-view" },
        { title: "用户管理", path: "./user-manage-view" },
        {
          title: "联系我们",
          path: "./contact-view",
          children: [
            { title: "联系我们", path: "./ContactUs" },
            { title: "客服中心", path: "./Service", tenantId: 1 },
          ],
        },
      ],
    };
  },
  computed: {
    tenantId() {
      return JSON.parse(sessionStorage.getItem("userInfo")).tenantId;
    },
  },
  created() {
    switch (this.$route.path) {
      case "/home":
        sessionStorage.setItem("tabIndex", 0);
        break;
      case "/oa-structure":
        sessionStorage.setItem("tabIndex", 1);
        break;
      case "/region-manage":
        sessionStorage.setItem("tabIndex", 2);
        break;
      case "/maintain":
        sessionStorage.setItem("tabIndex", 3);
        break;
      case "/Quarter":
        sessionStorage.setItem("tabIndex", 4);
        break;
      case "/Supplier":
        sessionStorage.setItem("tabIndex", 5);
        break;
      case "/membership-level":
        sessionStorage.setItem("tabIndex", 6);
        break;
      case "/ContactUs":
        sessionStorage.setItem("tabIndex", 7);
        sessionStorage.setItem("tabItemIndex", 0);
        break;
      case "/Service":
        sessionStorage.setItem("tabIndex", 7);
        sessionStorage.setItem("tabItemIndex", 1);
        break;
      default:
        this.tabIndex = sessionStorage.getItem("tabIndex");
    }
    this.list.forEach((item, index) => {
      if (this.$route.query.path == item.path) {
        this.tabIndex = index;
        sessionStorage.setItem("tabIndex", this.tabIndex);
      }
    });
  },
  mounted() {
    this.tabIndex = sessionStorage.getItem("tabIndex");
    this.tabItemIndex = sessionStorage.getItem("tabItemIndex");
  },
  methods: {
    linkTo() {
      this.$router.push({ path: "/first-page" });
    },
    clickTab(index, path) {
      this.list.forEach((item, i) => {
        if (index == i) {
          if (item.children && item.children.length > 0) {
            this.show = !this.show;
          } else {
            this.tabIndex = index;
            sessionStorage.setItem("tabIndex", this.tabIndex);
            this.$router.push(path);
          }
        }
      });
    },
    clickTabItem(index, path) {
      this.tabItemIndex = index;
      sessionStorage.setItem("tabItemIndex", this.tabItemIndex);
      this.$router.push(path);
      this.show = false;
    },
    loginUp() {
      this.$emit("loginUp");
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  position: relative;
  height: 60px;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 0 !important;
  border-bottom: 4px solid #d6d6d678;
  .logo {
    height: 60px;
    display: flex;
    align-items: center;
    width: 230px;
    justify-content: center;
    font-weight: bold;
    font-size: 20px;
    border-right: 2px solid #e6e6e6b0;
    cursor: pointer;
    & img {
      height: 35px;
      margin-right: 5px;
    }
  }
  .nav {
    margin-left: 50px;
    display: flex;

    .menu {
      position: relative;

      .item {
        font-size: 14px;
        margin: 0 20px;
        padding-bottom: 5px;
        font-weight: bold;
      }
      .menuItem {
        position: absolute;
        top: 40px;
        width: 100%;
        text-align: center;
        z-index: 99;
        & > div {
          background-color: white;
          height: 40px;
          line-height: 40px;
          cursor: pointer;
        }
      }
      .navActive {
        border-bottom: 3px solid #2f98ff;
        color: #2f98ff;
      }
      .itemActive {
        color: #2f98ff;
      }
    }
    ::v-deep .el-icon-arrow-down,
    .el-icon-arrow-up {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .login-out {
    position: absolute;
    right: 30px;
    padding: 0 5px;
    font-size: 15px;
    border-left: 2px solid #c0c0c07b;
    border-right: 2px solid #c0c0c07b;
    color: #81b3fb;
  }
}
</style>